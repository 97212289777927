.main {
  min-height: 100vh;
  height: auto;
}

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 1rem;
  margin: 0 auto;
}

.gradient-text-0 {
  background: -webkit-linear-gradient(#bfa3da, #84309c, #c735b0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-1 {
  background: -webkit-linear-gradient(#a79af9, #7aa8d2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-2 {
  background: -webkit-linear-gradient(#bfa3da, #743f93);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-3 {
  background: -webkit-linear-gradient(#c35ab1, #e9a8d9);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
  text-decoration-color: #84309c;
}

.title {
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: left;
}

.connect {
  margin-bottom: 2rem;
}

.description {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #555;
  border-radius: 5px;
  padding: 0.5em;
  font-size: 1.1rem;
}

.grid {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: #131313;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 440px;
  overflow: none;
  border: none;
}

.card-text {
  padding: 1rem;
}

.card img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px 4px 0 0;
  margin-bottom: 12px;
  border: none;
}

.card:hover,
.card:focus,
.card:active {
  background-color: #272c34;
  opacity: 80%;
}

.card h2 {
  margin: 0 0 0.75rem 0;
}

.card p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.75;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 768px) {
  .header {
    margin-left: 0;
  }
  .grid {
    width: 100%;
    flex-direction: column;
  }
  .title {
    font-size: 3rem;
    line-height: 1.15;
  }
}
@font-face {
  font-family: 'Hacked';
  src: url('../styles/fonts/Hacked.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.card,
.footer {
  border-color: #222;
}
.logo img {
  filter: invert(1);
}
.adtext {
  width: 15%;
}
.fa {
  font-family: 'Hacked';
  text-align: center;
  text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  color: white;
}
.colbox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.rowbox {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}
.rowboxDcolboxM {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}
.rowboxgap {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 10%;
  width: 80%;
}
.rowboxGAP {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 20%;
}
.bg1{
  background-image: url('../styles/images/bg1.gif');
  background-position: top;
  background-repeat: repeat-y;
  background-size: cover;
}
.widthboost{
  width: 200%;
}
.widthboost2{
  width: 180%;
}
.buta{
  background-size: 100% 100%;
  padding: 1.75%;
  border-radius: 15px;
  border: #000;
  box-shadow: 3px 3px 3px #000;
}
.buta:hover {

  transform: scale(1.025); /* Button enlarges slightly when hovered */
  box-shadow: 6px 6px 6px #000;
}

.buta:active {
  transform: scale(0.95); /* Button shrinks slightly when pressed */

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5); /* Optional: adds shadow for a pressed effect */
}

.butb{

  background-size: 100% 100%;
  padding: 1.75%;
  border-radius: 15px;
  border: #000;
  box-shadow: 3px 3px 3px #000;
}
.butb:hover {

  transform: scale(1.025); /* Button enlarges slightly when hovered */
  box-shadow: 6px 6px 6px #000;
}

.butb:active {

  transform: scale(0.95); /* Button shrinks slightly when pressed */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5); /* Optional: adds shadow for a pressed effect */
}
.img{
  border-color: #000;
  box-shadow: 3px 3px 3px #000;
  border-radius: 10px;
  width: 40%;
  height: 40%;
}
.marsides{
  margin-left: 10%;
  margin-right: 10%;
}

@media (max-width: 768px) {
  .bg2 {
    margin-left: 2.5%;
    margin-right: 2.5%;
    background-size: 200% 180%;
  }

  .bg3 {
    margin-left: 5%;
    margin-right: 5%;
  }
  .widthboost{
    width: 130%;
  }
  .widthboost2{
    width: 110%;
  }
  .rowboxDcolboxM{
    flex-direction: column;
  }
  .img{
    width: 70%;
    height: 70%;
  }
  .bg1{
    overflow: hidden;
  }
}


.w3button{
  background-image: url('../styles/images/button.gif');
  background-size: 100%  100%;
  box-shadow: 3px 3px 3px #000;
}
h1 {
  font-size: 300%;
}
h2 {
  font-size: 225%;
}
h3 {
  font-size: 175%;
}
h4 {
  font-size: 150%;
}
h5 {
  font-size: 125%;
}